.hero {

  &__inner {
    @include md-up {
      padding: $spc-8 $spc-6;
    }

    @include xl-up {
      padding: $spc-8;
    }
  }

  &__title {
    padding: $spc-5 $spc-3;
    font-size: 4rem;
    font-family: $font-family-bold;
    line-height: 120%;

    @include xl-up {
      padding: $spc-8 $spc-14 $spc-20;
      font-size: 140px;
    }
  }

  &__content {
    padding: $spc-8 $spc-3;

    @include xl-up {
      padding: $spc-8 $spc-14;
    }

    &_lead {

      @include md-up {
        width: 60%;
        margin-left: 40%;
      }

      // @include xl-up {
      //   padding: $spc-8 $spc-14;
      // }

      p {
        font-family: $font-family-bold;
        font-size: 3.2rem;
        color: $primary;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
  
        @include xl-up {
          font-size: 4.8rem;
        }
    } 
    }

    &_text {
      @include md-up {
        width: 50%;
      }
    }
  }
  
}

.homeHero,
.hero,
.projectHero {
  margin-top: 8.8rem;

  @include lg-up {
    margin-top: 12rem;
  }
}

.homeHero video {
  max-height: calc(100vh - 12rem);
}