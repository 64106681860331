.map {
  padding: $spc-10 $spc-3;

  @include md-up {
    padding: $spc-10 $spc-6;
  }

  @include xl-up {
    padding: $spc-8;
  }

  &__block {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}