.twoColumnList {

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spc-4;
    padding: $spc-10 $spc-3;

    @include md-up {
      gap: $spc-5;
      padding: $spc-10 $spc-6;
    }

    @include xl-up {
      padding: $spc-20 $spc-8;
    }
  }

  .twoColumn {

    &__inner {
      display: grid;
      grid-template-columns: 1fr;
      gap: $spc-3;
      padding: 0;

      @include md-up {
        grid-template-columns: 6fr 5fr;
        gap: $spc-5;

        &.column-reverse {
          grid-template-columns: 5fr 6fr;
        }
      }

      @include xl-up {
        gap: $spc-10;
      }
    }

    &__image {
      .image {
        width: 100%;
        aspect-ratio: 4 / 3;
        overflow: hidden;
      }

      img {
        width: 100%;
        aspect-ratio: 4 / 3;
        object-fit: cover;
        object-position: center;
      }
    } 

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: $spc-4;

      &.column-reverse {
        @include md-up {
          order: -1;
        }
      }
    }
  }
}