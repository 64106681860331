.projectNavigation {
  padding: $spc-10 $spc-3;

  @include md-up {
    padding: $spc-10 $spc-6;
  }

  @include xl-up {
    padding: $spc-20 $spc-8;
  }

  &__inner {

    @include xl-up {
      padding: 0 $spc-14;
    }

    .btn {
      min-height: $spc-8;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: $spc-4;

    @include md-up {
      flex-direction: row;
    }
  }
}