.notFound {

  &__content {

    &_wrapper {
      // background-color: #F6F6F8;
      padding-left: 2.4rem;
      padding-right: 2.4rem;

      @include md-up {
        padding-left: 5.8rem;
        padding-right: 5.8rem;
      }

      @include md-up {
        padding-left: 8rem;
        padding-right: 8rem;
      }
    }
  }
}