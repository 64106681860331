.icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    vertical-align: middle;
    fill: currentColor;
  }
  
  // div with svg sprite that includes all icons as symbol
  //stylelint-disable declaration-no-important
  .icon__sprite svg {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
  //stylelint-enable declaration-no-important
  
  .icon--m {
    width: 40px;
    height: 40px;
  }
  
  .icon--l {
    width: 60px;
    height: 60px;
  }
  
  // inlinesvg support reset
  // ----------------------------------------------------------
  .inlinesvg .icon {
    background-repeat: repeat;
    background-size: auto auto;
  }
  