.competences {

  &__inner {
    padding: $spc-10 $spc-3;

    @include md-up {
      padding: $spc-10 $spc-6;
    }

    @include xl-up {
      padding: $spc-10 $spc-8 $spc-20;
    }
  }

  &__header {
    margin-bottom: $spc-3;

    @include lg-up {
      width: 70%;
    }

    @include xl-up {
      padding: 0 $spc-14;
      margin-bottom: $spc-8;

      &.titel-right {
        margin-left: 30%;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spc-3;

    @include md-up {
      grid-template-columns: repeat(2, 1fr);
      gap: $spc-5;
    }

    @include xl-up {
      padding: 0 $spc-14;
      gap: $spc-8;
    }

    &.text-left .competences__bildWithText {
      @include md-up {
        order: -1;
        
        .competences__services {
          order: -1;
        }
      }
    }
  }

  .image {
    overflow: hidden;
  }

  &__singleBild {
    display: flex;
    align-items: center;
  }

  &__bildWithText {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spc-3;

    @include md-up {
      gap: $spc-5;
    }

    @include xl-up {
      gap: $spc-8;
    }
  }
}