.separator {
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  scroll-margin-top: 12rem;

  &.sliding {
    height: 260px;

    @include lg-up {
      height: 460px;
    }
  }

  &__inner {
    padding: $spc-5 0;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: all 1s ease;

    @include lg-up {
      padding: $spc-10 0;
    }

    &.sliding {
      padding: $spc-10 0;
      position: absolute;
      top: 0;
      left: 0;
      justify-content: flex-start;

      @include lg-up {
        padding: $spc-20 0;
      }

      & div {
        display: flex;
        animation: scrollText 40s infinite linear;
      }
    } 

    &.sliding .separator__inner_div2 {
      animation-delay: 20s;
    }

    .separator__text {
      padding: $spc-5 0;
      width: 100%;
      font-size: 40px;
      font-weight: 700;
      font-family: $font-family-bold;
      text-align: center;
  
      @include lg-up {
        font-size: 140px;
        padding: $spc-8 0;
      }

      &.text-white {
        color: $white !important;
      }
    }

    .separator__span {
      padding: 0 $spc-3;
    }

    @include lg-up {
      padding: 0 $spc-8;
    }
  }

  
}

@keyframes scrollText {
  from   { transform: translateX(0%); }
  to { transform: translateX(-100%); }
}