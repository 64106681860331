#CybotCookiebotDialogHeader {
  display: none !important;
}

#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

.CookieDeclaration {
  display: none !important;
}