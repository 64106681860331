.projectSlider {
  // background: rgba($secondary, 0.05);

  &__inner {
    padding: $spc-3 0;
    overflow: hidden;

    @include xl-up {
      padding: $spc-8 0;
    }
  }

  &__block {
    padding: $spc-3 0;
    padding-right: 2.4rem;

    @include md-up {
      padding: $spc-6 0;
      padding-right: 4.8rem;
    }

    @include xl-up {
      padding: $spc-8 0;
      padding-right: 9.6rem;
    }

    .swiper {
      padding-left: 2.4rem;
      margin-left: -2.4rem;
      transform: translateX(2.4rem);

      @include md-up {
        padding-left: 4.8rem;
        margin-left: -4.8rem;
        transform: translateX(4.8rem);
      }

      @include xl-up {
        padding-left: 9.6rem;
        margin-left: -9.6rem;
        transform: translateX(9.6rem);
      }
    }
  }


  .slider__item {
    cursor: grab;

    &_inner {
      padding: 4rem 0 2.4rem;
      position: relative;
    }

    .image {
      aspect-ratio: 4 / 3;
      overflow: hidden;
    }

    &_image {
      border-radius: 3rem;
      aspect-ratio: 4 / 3;
      object-fit: cover;
      object-position: center;
    }

    &_title {
      position: absolute;
      top: 0;
      background: light-dark($white, $black);
      font-family: $font-family-bold;
      color: $primary;
    }

    &_description {
      margin-top: $spc-2;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 120%; 
      color: rgba(0, 0, 0, 0.6);
    }

    &_icon {
      position: absolute;
      bottom: 0;
      background: light-dark($white, $cta);
      @include squaresize(4rem);

      @include xl-up {
        bottom: 0;
        @include squaresize(8rem);
      }
    }


    &_odd {

      .slider__item_title {
        left: 0;
        padding: 0 $spc-3 $spc-2 $spc-2;
        border-radius: 0 0 3rem 0;

        @include xl-up {
          padding: 0 $spc-5 $spc-3 $spc-3;
          border-radius: 0 0 5rem 0;
        }
      }


      .slider__item_icon {
        right: 0;
        padding: $spc-1-5 $spc-1-5 4px $spc-3;
        border-radius: 4rem 0 0 0;

        @include xl-up {
          padding: $spc-3 $spc-2 $spc-1 $spc-4;
          border-radius: 7rem 0 0 0;
        }
      }
    }

    &_even {

      padding-top: $spc-10;

      .slider__item_title {
        right: 0;
        padding: 0 $spc-3 $spc-2 $spc-2;
        border-radius: 0 0 0 3rem;

        @include xl-up {
          padding: 0 $spc-5 $spc-3 $spc-3;
          border-radius: 0 0 0 5rem;
        }
      }


      .slider__item_icon {
        left: 0;
        padding: $spc-1-5 $spc-3 4px $spc-1-5;
        border-radius: 0 4rem 0 0;

        @include xl-up {
          padding: $spc-2 $spc-4 $spc-1 $spc-2;
          border-radius: 0 7rem 0 0;
        }
      }

      .slider__item_description {
        text-align: right;
      }
    }

    .btn {
      margin-top: $spc-2;
      padding: $spc-1 $spc-5;

      @include xl-up {
        margin-top: $spc-3;
        padding: $spc-1 $spc-3;
      }

      svg {
        width: 5.5rem;
      }
    }
  }
}