.share {
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;

    .icon {
      width: 1.8rem;
      height: 1.8rem;
      margin: .8rem;
      color: $cta-selected;

      @include lg-up {
        margin: .4rem 1.2rem .4rem .4rem;
      }
    }

    &__breadcrumbs {
      a {
        color: $gray-500;
        font-size: 1.4rem;
      }

      &__item {
        .icon {
          color: $gray-500;
          width: 1rem;
          height: 1rem;
          margin-left: 1.2rem;
          cursor: auto;
        }
      }
    }

    .back,
    .share-button {
      cursor: pointer;
      color: light-dark($primary, $cta-dark);

      &__back {
        position: relative;
        color: $black;
        font-size: 1.8rem;

        &:after {
          content: '';
          width: 0.1rem;
          height: 100%;
          background: rgba($black, 0.08);
          position: absolute;
          right: 0;
        }
      }
    }

    .close-share {
      position: relative;
      cursor: pointer;
      margin-right: .4rem;

      @include lg-up {
        margin-right: .8rem;
      }

      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 50%;
        display: block;
        background: $primary;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }

    .share__open {
      display: flex;
      .icon {
        filter: brightness(0) saturate(100%) invert(12%) sepia(82%) saturate(4035%) hue-rotate(213deg) brightness(86%) contrast(98%);
      }
    }
  }