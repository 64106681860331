$hamburgerWidth: 100%;

$height-icon: 2.4rem;
$width-line: 5rem;
$height-line: 0.3rem;

$rotation: 45deg;
$translateY: calc($height-icon / 2);
$translateX: 0;

$time: 0.3s;

.header {
  width: 100%;
  background: $white;
  // overflow-y: visible;
  // overflow-x: clip;

  position: fixed;
  top: 0rem;
  z-index: 10;
  transition: all $time linear;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .1)) drop-shadow(0 1px 1px rgba(0, 0, 0, .06));;

  &.active {
    .hamburger-menu {
      transform: translate(0%, 0%);
      transition: 0.3s transform ease-in;
    }

    * {
      @include cursor-arrow-white;
    }
  }

  &.hide {
    transform: translateY(-100%);
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // overflow-x: clip;
    overflow-y: visible;
    padding: $spc-2 $spc-3;
    transition: 0.3s transform ease-in;

    @include lg-up {
      padding: $spc-4 $spc-12;
    }
  }

  &__logo {
    align-content: center;
    z-index: 10;
  }

  #hamburger-icon {
    width: $width-line;
    height: $height-icon;
    z-index: 10;
    
    .line {
      width: $width-line;
      height: $height-line;
      left: 0;
      border-radius: calc($height-line / 2);
      transition: all 0.3s;
      background: $black;
      
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
      }
      &.line-3 {
        top: 100%;
      }
    }
    &:hover, &:focus {
      .line-1 {
          transform: translateY(calc($height-line / 2 * -1));
      }
      .line-3 {
          transform: translateY(calc($height-line / 2));
      }  
    }
    &.active {
      .line-1 {
        transform: translateY($translateY) translateX($translateX) rotate($rotation);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: translateY(calc($translateY * -1)) translateX($translateX) rotate(calc($rotation * -1));
      }
    }
  }

  .hamburger-menu {
    top: 0;
    left: 0; 
    transform: translate(100vw, 0%);
    width: $hamburgerWidth;
    transition: 0.3s transform ease-out;
    height: 100vh;
    background: $primary;
    z-index: 8;

    @include cursor-arrow-white;

    &__nav {
      padding: $spc-4 $spc-3;
      position: relative;

      @include lg-up {
        padding: $spc-4 $spc-12;
      }
    }

    &_slogan {
      position: absolute;
      bottom: $spc-4;
      right: $spc-4;
      width: 14rem;

      @include lg-up {
        width: 26rem;
      }

      @include xl-up {
        bottom: $spc-4;
        right: $spc-12;
        width: 38rem;
      }
    }

    a {
      font-size: 2.4rem;
      color: $black;

      @include lg-up {
        font-size: 5.6rem;
      }

      &:hover {
        color: $cta-dark;
      }
    }
  }
}
