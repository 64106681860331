.contactPerson {
  background: $secondary-2;

  &__inner {
    padding: $spc-10 $spc-3;

    @include md-up {
      padding: $spc-10 $spc-6;
    }

    @include xl-up {
      padding: $spc-20 $spc-8;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $spc-3;
    padding-top: $spc-3;

    @include md-up {
      padding-top: $spc-5;
      flex-direction: row-reverse;
      align-items: center;
      gap: 0;
    }

    @include lg-up {
      justify-content: center;
    }

    @include xl-up {
      padding-top: $spc-20;
    }
  }

  &__data {

    @include md-up {
      padding-right: $spc-3;
    }

    @include lg-up {
      padding-right: $spc-6;
    }

    @include xl-up {
      width: 25%;
      padding-right: $spc-8;
    }

    &_role,
    &_email {
      margin-bottom: 0;
      font-size: 1.8rem;
      line-height: 135%;

      @include md-up {
        font-size: 2.4rem;
      }
    }

    &_email {
      font-family: $font-family-bold;

      span {
        font-family: $font-family-base;
      }
    }
  } 

  &__image {

    @include lg-up {
      width: 33%;
    }
  }

  .image {
    width: 100%;
    aspect-ratio: 3 / 4;
    overflow: hidden;

    img {
      width: 100%;
      aspect-ratio: 3 / 4;
      object-fit: cover;
      object-position: center;
    }
  }
}