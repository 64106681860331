.fullblockLink {
  padding: $spc-12 0;

  @include lg-up {
    padding: $spc-20 0;
  }

  &__inner {
    padding: $spc-5 $spc-3;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

    @include lg-up {
      padding: $spc-8 $spc-14;
    }
  }

  &__text,
  &__text p {
    line-height: 125%;
    text-align: center;
    margin-bottom: 0;

    &.text-normal,
    &.text-normal p {
      font-size: 26px;
      font-weight: 400;

      @include lg-up {
        font-size: 64px;
      }
    }

    &.text-xLarge,
    &.text-xLarge p {
      font-family: $font-family-bold;
      font-size: 46px;

      @include lg-up {
        font-size: 140px;
      }
    }
  }

  .btn--default > svg {
    width: 5.5rem;
  }
}