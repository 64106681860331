.btn {
  position: relative;
  padding: 1.2rem 2.4rem;
  border-radius: 4rem;
  transition: all 0.5s ease;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;
  min-width: fit-content;
  max-width: fit-content;
  line-height: 120%;
  transition: all 0.3s linear;

  // @include lg-up {
  //   gap: $cpc-2;
  // }

  svg {
    width: 5.5rem;
  }

  &--default {
    background-color: transparent;
    border: 0.1rem solid;
    @include lightDarkMode(border-color, $secondary, $cta-dark);
    @include lightDarkMode(color, $secondary, $cta-dark);

    span {
      @include lightDarkMode(color, $secondary, $cta-dark);
    }

    &.text-white * {
      color: $white;
    }

    &:hover {
      @include lightDarkMode(color, $cta-hover, $cta-hover-dark);
      @include lightDarkMode(border-color, $cta-hover, $cta-hover-dark);

      span {
        @include lightDarkMode(color, $cta-hover, $cta-hover-dark);
      }

      svg {
        @include lightDarkMode(fill, $cta-hover, $cta-hover-dark);
      }
    }
    &:active {
      @include lightDarkMode(color, $cta-selected, $cta-selected-dark);
      @include lightDarkMode(border-color, $cta-selected, $cta-selected-dark);
    }

    svg {
      @include lightDarkMode(fill, $secondary, $cta-dark);
    }
  }

  &--primary {
    @include lightDarkMode(background, $primary, $white);
    @include lightDarkMode(color, $white, $primary);

    &:hover {
      @include lightDarkMode(background, $cta-hover, $cta-hover-dark);
    }

    &:active {
      @include lightDarkMode(background, $cta-selected, $cta-selected-dark);
    }
  }

  &--submit {
    background: $primary;  
    max-width: 100%;
    width: 100%;
    height: 6.4rem;
    font-size: 2rem;
    color: $white;
    @include cursor-arrow-white;

    &:hover {
      background: $cta-hover;
    }

    &:active {
      background: $cta-selected;
    }

    span {
      color: $white;
    }
  }

  &--ghost {
    background-color: transparent;
    border: 0.1rem solid #CCD7E9;
    color: #CCD7E9;

    &:hover {
      border-color: $white;
      color: $white;
    }
  }

  &--light {
    background-color: transparent;
    border: 0.1rem solid;
    @include lightDarkMode(color, $white, $primary);
    @include lightDarkMode(border-color, $white, $primary);

    &:hover {
      @include lightDarkMode(color, $cta, $cta-dark);
      @include lightDarkMode(border-color, $cta, $cta-dark);
    }
  }

  &--secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
      background-color: rgba($secondary, 0.5);
    }
   
  }

  &.right {
    flex-direction: row-reverse;
  }

  &--inline {
    @include lightDarkMode(color, $cta, $cta-dark);

    &:hover {
      @include lightDarkMode(color, $cta-hover, $cta-hover-dark);
      @include lightDarkMode(border-color, $cta-hover, $cta-hover-dark);
    }

    &:active {
      @include lightDarkMode(color, $cta-selected, $cta-selected-dark);
      @include lightDarkMode(border-color, $cta-selected, $cta-selected-dark);
    }
  }
}