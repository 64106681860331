.projectPage {
  margin-top: 8.8rem;

  @include lg-up {
    margin-top: 12rem;
  }

  &__inner {
    padding: $spc-5 $spc-3;

    @include md-up {
      padding: $spc-8 $spc-6;
    }
  
    @include xl-up {
      padding: $spc-8;
    }
  }

  &__title {
    @include xl-up {
      padding: $spc-8 $spc-14;
    }
  }

  .swiper-slide {
    cursor: grab;
  }

  &__navigation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: $spc-3 0 0;

    @include md-up {
      padding: $spc-6 0 0;
    }

    @include xl-up {
      padding: $spc-8 $spc-14 0;
    }

    .btn {
      margin: 0 $spc-3 $spc-3 0;

      span {
        text-transform: capitalize;
      }

      svg {
        height: 2rem;
      }
    }
  }

  &__slider {

    .swiper {
  
      &-wrapper {
        display: flex;
        align-items: center;
    
        .image,
        img {
          width: 100%;
          aspect-ratio: 16 / 9;
          object-fit: cover;
          object-position: center;
        }
      }
  
      &-slide {
        .image {
          margin: 0 auto; 
          
          @include md-up {
            margin: 0;
          }
        }
      }
  
      &-navigation {
        position: relative;
        background: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $spc-3;
  
        @include md-up {
          padding: 0 $spc-5;
        }
  
        @include xl-up {
          padding: 0;
        }
      }
  
      &-pagination {
        position: relative;
      }
  
      &-pagination-fraction {
        top: auto;
        bottom: auto;
        font-size: 2.8rem;
        text-align: left;
        padding: $spc-2 0;
      }
    }
  
    .slider__nav {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $spc-5;
  
      .swiper-button-next,
      .swiper-button-prev {
        position: relative;
        margin: 0;
        width: 8rem;
        @include cursor-arrow;
  
        &::after {
          display: none;
        }
      }
    }
  }

  &__description {
    padding: $spc-5 $spc-0;

    @include md-up {
      padding: $spc-8 $spc-0;
    }

    @include xl-up {
      padding: $spc-8 $spc-14;
    }

    &_content {
      padding-bottom: $spc-8;
    }

    h2 {
      font-size: 2rem;
      text-transform: uppercase;
      margin-bottom: 3.2rem;

      @include xl-up {
        font-size: 2.4rem;
      }
    }

    ul {
      padding-left: 3.6rem;
    }

    a {
      text-decoration: underline;
    }
  }

  .objectParams {

    @include md-up {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $spc-3;
    }

    @include xl-up {
      gap: $spc-8;
    }

    &__item {
      padding-bottom: $spc-8;

      &:last-of-type {
        padding-bottom: 0;
      }
  
      &_row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spc-1;
        align-items: center;
        padding: $spc-1 0;
        font-size: 1.8rem;
        border-bottom: 1px solid #DBDBDB;

        @include xl-up {
          font-size: 2.4rem;
        }
      }
  
      &_row-checkbox {
        grid-template-columns: 1fr;
      }
    }
  }

  .objectPageForm {

    &__inner {

      @include md-up {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spc-3;
      }

      @include xl-up {
        gap: $spc-6;
      }
    }

    .image {
      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center;
      width: 90%;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto $spc-4;

      @include md-up {
        width: 50%;
      }
    }

    &__image {
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center;
    }

    &__contactperson {

      padding: $spc-3 0;

      @include md-up {
        padding: $spc-6 0;
      }

      .contactperson__data {
        @include md-up {
          font-size: 2rem;
        }

        @include lg-up {
          font-size: 2.4rem;
        }
      }
    }

    .contactperson__data:hover {
      color: $cta;

      svg {
        fill: $cta;
      }
    }

    &__company {
      padding-top: $spc-4;

      p,
      a {
        margin-bottom: 2px;
      }

      a:hover p {
        color: $cta;
      }
    }

    .radio__wrapper.input__checkbox {
      input[type=checkbox] + label {
        font-size: 1.44rem;
        color: light-dark($black, $white);
  
        a {
          color: $primary;
        }
      }
  
      &:hover input[type=checkbox] + label  {
        color: light-dark($black, $white);
      }
    }
  }
}

.js-form {
  scroll-margin-top: 8.8rem;

  @include md-up {
    scroll-margin-top: 12rem;
  }
}