.footer {
  background: $black;

  &__inner {
    max-width: 192rem;
    margin: 0 auto;
    display: grid;
    padding: $spc-3;
    gap: $spc-6;
    padding: $spc-6 $spc-3;

    @include md-up {
      padding: $spc-5 $spc-6;
      grid-template-columns: 1fr 1fr;
      gap: 3.2rem;
    }

    @include lg-up {
      padding: $spc-6;
    }

    @include xl-up {
      padding: $spc-10 $spc-18;
      grid-template-columns: 3fr 3fr 2fr 3fr;
    }

    .text-white,
    .text-white p {
      color: $white !important;
    }

    a.text-white:hover {
      color: $cta !important;

      span {
        color: $cta !important;
      }

      svg {
        fill: $cta !important;
      }
    }
  }

  &__title {
    font-family: $font-family-bold;

    a {
      font-size: 1.6rem;
    }
  } 

  svg {
    fill: $white;
  }

  &__address {
    p {
      margin: 0;
    }

    svg {
      @include squaresize(1.6rem);
      fill: $white !important;
    }
  }

  .footer-link {
    text-decoration: underline;
    color: $white;

    &:hover {
      color: $cta;
    }
  }

  &__social {
    display: flex;
    gap: $spc-3;

    @include md-up {
      flex-direction: column
    }

    @include xl-up {
      align-items: center;
    }

    svg {
      @include squaresize(3.2rem);
      fill: $primary;

      &:hover {
        fill: $cta-hover;
      }
    }
  }

  &__slogan {

    &_image {
      width: 100%;
      max-width: 21.6rem;
    }
  }

}