.saleObjectsList {

  padding: $spc-10 $spc-3;

  @include md-up {
    padding: $spc-10  $spc-6;
  }

  @include xl-up {
    padding: $spc-10 $spc-14;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spc-3;
    margin-bottom: $spc-8;

    @include lg-up {
      grid-template-columns: repeat(2, 1fr);
    }

    @include xl-up {
      gap: $spc-8;
    }

    &_description {

      @include md-up {
        padding: $spc-4 0;
      }
    }

    &_row {
      display: grid;
      grid-template-columns: 45% 55%;
      gap: $spc-1;
      border-bottom: 1px solid $primary-2;
      margin-bottom: $spc-1;

      p {
        margin: 0;
      }
    }

    &_image {
      height: 100%;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
      aspect-ratio: 4 / 3;
    }
  }
}