// Small devices
@mixin sm-up {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin md-up {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin lg-up {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// XL  devices
@mixin xl-up {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

// XXL devices
@mixin xxl-up {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin squaresize($size) {
  width: $size;
  height: $size;
}

@mixin font($size, $weight, $line-height, $color: $black) {
  font-size: $size;
  font-weight: $weight; 
  line-height: $line-height;
  color: $color;
}

@mixin lightDarkMode($property, $lightValue, $darkValue) {
  #{$property}: light-dark($lightValue, $darkValue);
}

@mixin squaresize($size) {
  width: $size;
  height: $size;
}

@mixin cursor-white {
  cursor: url('images/cursor-white.svg') 0 0, auto !important;
}

@mixin cursor-arrow {
  cursor: url('images/cursor-arrow2.svg') -28 0, auto !important;
}

@mixin cursor-arrow-white {
  cursor: url('images/cursor-arrow-white.svg') -14 0, auto !important;
}
