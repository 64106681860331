.nextProjects {

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spc-2 $spc-3;
    border-bottom: 1px solid light-dark($black, $white);

    @include md-up {
      padding: $spc-2 $spc-6;
    }

    @include xl-up {
      padding: $spc-2 $spc-18;
    }

    &:hover {
      background: rgba(247, 47, 107, 0.20);
      transition: all 0.3s linear;

      .nextProjects__item_title,
      .nextProjects__item_title span {
        color: $cta;
      }

      .icon {
        transform: translateX($spc-2);
      }
    }

    &_title {
      max-width: 70%;

      span {
        font-family: $font-family-base;
      }
    }

    .icon {
      width: 8rem;
      transition: all 0.3s linear;
    }
  }
}