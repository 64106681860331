.projectHero {

  &__inner {
    padding: 0 $spc-3;

    @include md-up {
      padding: $spc-4 $spc-7;
    }

    @include xl-up {
      padding: $spc-8 $spc-14;
    }
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $spc-3;
    padding: $spc-5 0;

    @include md-up {
      padding: 0;
    }

    &_title {
      position: absolute;
      left: 0; 
      top: 0;
      background-color: light-dark($white, $black);
      font-family: $font-family-bold;
      color: $primary;
      margin-bottom: 0rem;
      padding: 0 $spc-3 $spc-1-5 $spc-1-5;
      border-radius: 0 0 3rem 0;

      @include md-up {
        font-size: 4.8rem;
      }

      @include xl-up {
        font-size: 10.4rem;
        border-radius: 0 0 12rem 0;
        padding: 0 $spc-6 $spc-9 $spc-5;
      }
    }

    .projectHero__image-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;

      @include md-up {
        align-items: flex-end;
      }

      .image {
        width: 100%;
        aspect-ratio: 4 / 3;
        overflow: hidden;
  
        @include md-up {
          width: 80%;
        }

        img {
          object-fit: cover;
          object-position: center;
          aspect-ratio: 4 / 3;
        }
      }

      &.vertical {

        @include md-up {
          flex-direction: row-reverse;
          align-items: flex-end;
          justify-content: space-between;
          gap: $spc-4;
        }

        .image {
          aspect-ratio: 3 / 4;
    
          @include md-up {
            width: 50%;
          }

          img {
            height: 100%;
            aspect-ratio: 3 / 4;
          }
        }

        .projectHero__header_description {
          @include md-up {
            width: 50%;
          }
        }
      }

      .projectHero__image {
        border-radius: 3rem;

        @include xl-up {
          border-radius: 6.4rem;
        }
      }
    }

    &_description {
      margin-top: $spc-3;
      padding-bottom: $spc-5;
      width: 100%;
  
      @include md-up {
        margin-top: $spc-5;
        padding-bottom: $spc-8;
        width: 80%;
      }

      &-row {
        border-bottom: 1px solid rgba(0, 0, 0, 0.6);

        &,
        & span {
          font-size: 1.8rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 140%;    
        }

        &:first-of-type {
          border-top: 1px solid rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  &__content {
    padding: $spc-8 $spc-3;

    @include xl-up {
      padding: $spc-8 $spc-14;
    }

    &_lead {
      font-family: $font-family-bold;
      font-size: 3.2rem;
      color: $primary;
      text-transform: uppercase;

      @include md-up {
        width: 60%;
        margin-left: 40%;
      }

      @include xl-up {
        padding: $spc-8 $spc-14;
        font-size: 4.8rem;
      }
    }

    &_text {
      @include md-up {
        width: 50%;
      }
    }
  }
}