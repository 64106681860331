.pagination {
  &__row { 
    gap: 2.4rem;

    @include lg-up {
      gap: 4rem;
    }
  }
  &__meta {
    font-size: 1.4rem;
    color: light-dark(rgba($black, 0.56), rgba($white, 0.56));
  }

  &__main {
    font-size: 1.6rem;
  }

  &__text {

    &.prev {
      text-align: right;
    }
  }

  &__icon {
    svg {
      width: 1.6rem;
      height: 1.6rem;
      color: $info;
    }
  }

  &__item {
    &:hover {
      svg {
        color: light-dark($cta, $cta-dark);
      }
      .prev p,
      .next p {
        color: light-dark($cta, $cta-dark);
      }
    }
  }

  &__separator {
    svg {
      color: $info;
    }
  }
}