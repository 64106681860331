.informationBlock {


  &__inner {
    padding: $spc-10 $spc-3;

    @include md-up {
      padding: $spc-10 $spc-6;
    }
  
    @include xl-up {
      padding: $spc-10 $spc-14;
    }
  }

  .h1 {

    @include xl-up {
      width: 65%;
    }
  }

  &__content,
  &__buttons {

    @include xl-up {
      width: 65%;
      margin-left: 35%;
    }
  }
}

