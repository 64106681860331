.projectList {
  background: #F8F3F4;;
  padding: $spc-10 0 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: $spc-10;

  @include xl-up {
    padding: 0;
  }

  &__item {
    padding: 0 $spc-3;

    @include md-up {
      padding: $spc-4 $spc-7;
    }

    @include xl-up {
      padding: $spc-8 $spc-14;
    }
  }

  .projectList__item {

    &_inner {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &_image {
      border-radius: 3rem;
      height: 100%;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 4 / 3;

      @include xl-up {
        border-radius: 6.4rem;
      }
    }

    &_head {
      position: absolute;
      top: 0;
      background: #F8F3F4;
      display: flex;
      flex-direction: column;

      .btn--default {
        border-color: $secondary;
        color: $secondary;
    
        span {
          color: $secondary;
        }
    
        svg {
          fill: $secondary;
        }
    
        &:hover {
          border-color: $cta;
          color: $cta;
    
          span {
            color: $cta;
          }
    
          svg {
            fill: $cta
          }
        }
      }
    }

    &_title {
      font-family: $font-family-bold;
      color: $primary;
      margin-bottom: 0rem;

      @include md-up {
        margin-bottom: 1.6rem;
      }

      @include xl-up {
        font-size: 7.2rem;
        margin-bottom: 3.2rem;
      }
    }

    &_description {
      margin-top: $spc-2;
      font-size: 1.8rem;
      font-weight: 400;
      line-height: 120%; 
      color: rgba(0, 0, 0, 0.6);

      @include md-up {
        width: 80%;
      }
    }

    &_icon {
      position: absolute;
      bottom: 0;
      background: #F8F3F4;
    
      img {
        @include squaresize(4rem);
      }

      @include md-up {
        
        img {
          @include squaresize(6rem);
        }
      }

      @include lg-up {
        
        img {
          @include squaresize(12rem);
        }
      }

      @include xl-up {
        bottom: 0;
        
        img {
          @include squaresize(16rem);
        }
      }
    }

    &_image-wrapper {

      @include md-up {
        display: flex;
      }
    }

    .image {
      aspect-ratio: 4 / 3;
      overflow: hidden;

      @include md-up {
        width: 80%;
      }
    }


    &_odd {

      .projectList__item_head {
        left: 0;
        padding: 0 $spc-3 $spc-1-5 $spc-1-5;
        border-radius: 0 0 3rem 0;

        @include xl-up {
          padding: $spc-5;
          border-radius: 0 0 12rem 0;
        }
      }

      .projectList__item_image-wrapper {
        justify-content: flex-end;
      }

      .projectList__item_description {
        @include md-up {
          align-self: flex-end;
        }
      }

      .projectList__item_icon {
        right: 0;
        padding: $spc-1-5 $spc-1-5 4px $spc-3;
        border-radius: 4rem 0 0 0;

        @include xl-up {
          padding: $spc-5 $spc-5 $spc-2 $spc-8;
          border-radius: 12rem 0 0 0;
        }
      }
    }

    &_even {

      .projectList__item_head {
        right: 0;
        padding: 0 $spc-1-5 $spc-1-5 $spc-3;
        border-radius: 0 0 0 3rem;

        @include xl-up {
          padding: $spc-5;
          border-radius: 0 0 0 12rem;

          .btn {
            align-self: flex-end;
          }
        }
      }

      .projectList__item_image-wrapper {
        justify-content: flex-start;
        // align-items: flex-start;
      }

      .projectList__item_description {
        @include md-up {
          text-align: right;
        }
      }

      .projectList__item_icon {
        left: 0;
        padding: $spc-1-5 $spc-3 4px $spc-1-5;
        border-radius: 0 4rem 0 0;

        @include xl-up {
          padding: $spc-5 $spc-8 $spc-2 $spc-5;
          border-radius: 0 12rem 0 0;
        }
      }
    }

    .btn {
      padding: $spc-1 $spc-5;

      @include xl-up {
        padding: $spc-1 $spc-3;
        font-size: 2rem;
      }

      svg {
        width: 5.5rem;
      }
    }
  }

  .projectNavigation__inner {
    background: $white;
    padding: $spc-3 $spc-3 $spc-10;

    @include md-up {
      padding: $spc-6 $spc-7 $spc-10;
    }

    @include xl-up {
      padding: $spc-10 $spc-14 $spc-20;
    }
  }
}