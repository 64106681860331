.twoColumn {
  padding: $spc-10 $spc-3;

  @include md-up {
    padding: $spc-10 $spc-6;
  }

  @include xl-up {
    padding: $spc-8;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $spc-4;

    @include md-up {
      grid-template-columns: repeat(2, 1fr);
      gap: $spc-3;
    }

    @include xl-up {
      gap: $spc-8;
      padding: 0 $spc-14;
    }
    
    .row-reverse {
      @include md-up {
        order: -1;
      }
    }
  }

  &__right {

    &_title {
      margin-top: $spc-4;
  
      @include xl-up {
        margin-top: $spc-10;
      }
    }

    &_image {
      aspect-ratio: 4 / 3;
      width: 100%;
      overflow: hidden;

      img {
        aspect-ratio: 4 / 3;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__left {
    aspect-ratio: 3 / 4;
    width: 100%;
    overflow: hidden;

    @include md-up {
      margin-top: $spc-6;
    }

    @include xl-up {
      margin-top: $spc-14;
    }

    img {
      aspect-ratio: 3 / 4;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}