@import '_config/mixins';
@import '~bootstrap/scss/functions';
@import '_config/variables';
@import '_config/colorVariables';
@import '_config/fontVariables';

@import '_config/fonts';
@import '_config/utilities';
@import 'bootstrap/scss/bootstrap-grid.scss';
@import 'bootstrap/dist/css/bootstrap-utilities.min.css';
@import '_config/settings';
@import '_config/cookiebot';

//NPM packages
@import 'swiper/swiper-bundle';


@import '01-atoms/typography/typography';
@import '01-atoms/button/button';
@import '01-atoms/icons/icon';
@import '01-atoms/navigation/navigation';
@import '01-atoms/readmore/readmore';
@import '01-atoms/inputs/inputs';

@import '02-molecules/accordion/accordion';
@import '02-molecules/hero/hero';
@import '02-molecules/iconList/iconList';
@import '02-molecules/share/share';
@import '02-molecules/slider/slider';
@import '02-molecules/search/search';
@import '02-molecules/separator/separator';
@import '02-molecules/fullblockLink/fullblockLink';
@import '02-molecules/mediaBlock/mediaBlock';
@import '02-molecules/projectSlider/projectSlider';
@import '02-molecules/contactBlock/contactBlock';
@import '02-molecules/newsTeaser/newsTeaser';
@import '02-molecules/competences/competences';
@import '02-molecules/kpi/kpi';
@import '02-molecules/contactInfo/contactInfo';
@import '02-molecules/pagination/pagination';
@import '02-molecules/filter/filter';
@import '02-molecules/projectHero/projectHero';
@import '02-molecules/gallery/gallery';
@import '02-molecules/contactPerson/contactPerson';
@import '02-molecules/teamList/teamList';
@import '02-molecules/newsList/newsList';
@import '02-molecules/content/content';
@import '02-molecules/informationBlock/informationBlock';
@import '02-molecules/projectList/projectList';
@import '02-molecules/twoColumnList/twoColumnList';
@import '02-molecules/nextProjects/nextProjects';
@import '02-molecules/contactForm/contactForm';
@import '02-molecules/map/map';
@import '02-molecules/officeLocation/officeLocation';
@import '02-molecules/projectNavigation/projectNavigation';
@import '02-molecules/saleObjectsList/saleObjectsList';
@import '02-molecules/notFound/notFound';
@import '02-molecules/twoColumn/twoColumn';
@import '02-molecules/projectPage/projectPage';

@import '03-organisms/header/header';
@import '03-organisms/footer/footer';
