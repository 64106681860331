.newsTeaser {
  padding: $spc-8 $spc-3;

  @include xl-up {
    padding: $spc-10 $spc-8;
  }

  &__inner {


  }

  &__item {
    // padding: 0 $spc-12;
    grid-template-columns: 1fr;
    gap: $spc-3;
    margin-bottom: 8rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include lg-up {
      grid-template-columns: 4fr 6fr;
      gap: $spc-8;
      align-items: center;

      &_odd {
        grid-template-columns: 6fr 4fr;

        .newsTeaser__item_content {
          order: -1;
        }
      }
    }

    @include xl-up {
      padding: 0 $spc-12;
    }
  }

  .image {
    width: 100%;
    aspect-ratio: 1 / 1;

    img {
      width: 100%;
      aspect-ratio: 1 / 1;
      object-fit: cover;
      object-position: center;
    }
  }
}
