.gallery {

  @include xl-up {
    padding: $spc-8;
  }
}

.gallerySlider {

  .swiper {
    padding: $spc-10 $spc-3;
    background: rgba($secondary, 0.05);
    cursor: grab;

    @include md-up {
      padding: $spc-10 $spc-5;
    }

    @include lg-up {
      padding:$spc-14;
    }

    &-wrapper {
      display: flex;
      align-items: center;
  
      .image,
      img {
        max-height: 48rem;
        width: fit-content;
      }
    }

    &-slide {
      
      .image {
        margin: 0 auto; 
        
        @include md-up {
          margin: 0;
        }
      }
    }

    &-navigation {
      position: relative;
      background: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 $spc-3;

      @include md-up {
        padding: 0 $spc-5;
      }

      @include xl-up {
        padding: 0;
      }
    }

    &-pagination {
      position: relative;
    }

    &-pagination-fraction {
      top: auto;
      bottom: auto;
      font-size: 2.8rem;
      text-align: left;
      padding: $spc-2 0;
    }
  }

  .slider__nav {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spc-5;

    .swiper-button-next,
    .swiper-button-prev {
      position: relative;
      margin: 0;
      width: 8rem;
      @include cursor-arrow;

      &::after {
        display: none;
      }
    }
  }
}

.galleryList {

  padding: $spc-10 $spc-3;

  @include md-up {
    padding: $spc-10 $spc-6;
  }

  @include xl-up {
    padding: 0;
  }

  &__header {
    padding-bottom: $spc-3;

    @include md-up {
      padding-bottom: $spc-5;
    }
  
    @include xl-up {
      padding: $spc-8 $spc-14 $spc-20;
    }
  }

  &__item {
    padding: $spc-3 0;

    @include md-up {
      &:nth-child(odd) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    @include xl-up {
      padding: $spc-8 $spc-20;

      &:nth-child(odd) {
        padding: $spc-8 $spc-8;
      }
    }
  }

  .bild-portrait {
    .galleryList__item_wrapper {
      @include md-up {
        width: 40%;
      }
    }
  }

  .bild-landscape {
    .galleryList__item_wrapper {
      @include md-up {
        width: 80%;
      }
    }
  }

  .bild-square {
    .galleryList__item_wrapper {
      @include md-up {
        width: 50%;
      }
    }
  }
}

.swiper-pagination,
.swiper-button-next,
.swiper-button-prev {
  z-index: 9;
}