.kpi {

  &__inner {
    padding: $spc-10 $spc-3;

    @include xl-up {
      padding: $spc-10 $spc-8 $spc-20;
    }
  }

  &__header {
    margin-bottom: $spc-3;

    @include lg-up {
      width: 70%;
    }

    @include xl-up {
      padding: 0 $spc-14;
      margin-bottom: $spc-8;

      &.titel-right {
        margin-left: 30%;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spc-3;

    @include md-up {
      // padding: 0 $spc-5;
      grid-template-columns: repeat(2, 1fr);
      gap: $spc-5;
    }

    @include xl-up {
      gap: $spc-1-5;

      &.kpi__columns-5 {
        grid-template-columns: repeat(5, 1fr);
      }

      &.kpi__columns-6 {
        grid-template-columns: repeat(3, 1fr);
        row-gap: $spc-4;
      }

      &.kpi__columns-7 {
        grid-template-columns: repeat(7, 1fr);
      }
    }
  }

  &__item {
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $spc-3;

    @include lg-up {
      padding: $spc-5;
    }

    &_title {
      hyphens: auto;
    }

    .image {
      @include squaresize(14.4rem);
    }

    &_separator {
      width: 30%;
      height: 2px;
      background: $primary;
    }

    &_number {
      color: $primary !important;
    }

    &_content {
      text-align: center;

      * {
        color: $black;
      }
    }

    &_text,
    &_text p {
      text-align: center;
      font-size: 1.4rem;
    }
  }

}