.newsList {

  &__item {
    padding: $spc-8 $spc-3;
    display: grid;
    grid-template-columns: repeat(1, 100%);

    @include md-up {
      padding: $spc-8 $spc-6;
    }

    @include lg-up {
      grid-template-columns: repeat(2, calc(50% - $spc-1-5));
      gap: $spc-3;
    }

    @include xl-up {
      padding: $spc-20 $spc-8;
      grid-template-columns: calc(60% - $spc-4) calc(40% - $spc-4);
      gap: $spc-8;
    }

    &_odd {
      background: light-dark(transparent, $white);

      .swiper-pagination-bullet {
        border: 2px solid $cta;

        &.swiper-pagination-bullet-active {
          background: $cta !important;
        }
      }

      * {
        color: light-dark($black, $black);
      }
    }

    &_even {
      background: rgba($secondary, 0.05);

      * {
        color: light-dark($black, $white);
      }

      .swiper-pagination-bullet {
        border: 2px solid light-dark($cta, $white);

        &.swiper-pagination-bullet-active {
          background: light-dark($cta, $white) !important;
        }
      }

      @include lg-up {
        .newsList__item_content {
          order: -1;
        }
      }

      @include xl-up {
        grid-template-columns: calc(40% - $spc-4) calc(60% - $spc-4);
      }
    }
  }

  .swiper-slide {
    cursor: grab;
  }

  .newsswiper-navigation {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: $cta;
    }
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: transparent;
    }
  }

  .newsswiper-pagination {
    width: fit-content;
  }
}