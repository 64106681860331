.navigation {
  &__item {
    @include lightDarkMode(color, $black, $white);

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {
      @include lightDarkMode(color, $cta, $cta-dark);
    }

    &.active {
      @include lightDarkMode(color, $cta-selected, $cta-selected-dark);
      border-bottom: 2px solid;
    }
  }
}