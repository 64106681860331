.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

// Background colors
.bg-primary {
  background-color: light-dark($primary, $primary-dark) !important;
}

.bg-secondary {
  background-color: light-dark($secondary, $secondary-dark) !important;
}

.bg-white {
  background-color: light-dark($white, $black) !important;

  .btn--default {
    color: light-dark($secondary, $white);

    span {
      color: light-dark($secondary, $white);
    }

    svg {
      fill: light-dark($secondary, $white);
    }

    &:hover {
      border-color: $cta;
      color: $cta;

      span {
        color: $cta;
      }

      svg {
        fill: $cta
      }
    }
  }
}

.bg-black {
  background-color: light-dark($black, $white) !important;
}

.bg-darkRed {
  background-color: $secondary;
  @include cursor-white;

  .btn--default {
    border-color: $white;
    @include cursor-arrow-white;

    span {
      color: $white;
    }

    svg {
      fill: $white;
    }

    &:hover {

      svg {
        fill: $cta
      }

      span {
        color: $cta;
      }
    }
  }
}

.bg-rose {
  background-color: $primary;
  @include cursor-white;

  .btn--default {
    border-color: $white;
    @include cursor-arrow-white;

    span {
      color: $white;
    }

    &:hover {
      border-color: $secondary;
      color: $secondary;

      span {
        color: $secondary;
      }

      svg {
        fill: $secondary
      }
    }

    svg {
      fill: $white;
    }
  }
}

.bg-grey {
  background-color: $primary-2;
}

.bg-creme {
  background-color: $secondary-2;
}


.bg-lightCreme {
  background-color:rgba(107, 16, 44, 0.05);
}

.bg-grey,
.bg-creme,
.bg-lightCreme {

  .btn--default {
    color: light-dark($secondary, $white);

    span {
      color: light-dark($secondary, $white);
    }

    svg {
      fill: light-dark($secondary, $white);
    }

    &:hover {
      border-color: $cta;
      color: $cta;

      span {
        color: $cta;
      }

      svg {
        fill: $cta
      }
    }
  }
}

.bg-gray-100 {
  background-color: light-dark($gray-100, $gray-800) !important;
}

.bg-gray-200 {
  background-color: light-dark($gray-200, $gray-800) !important;
}

.bg-gray-300 {
  background-color: light-dark($gray-300, $gray-700) !important;
}

.bg-gray-400 {
  background-color: light-dark($gray-400, $gray-600) !important;
}

// Text colors
.text-primary,
.text-primary p {
  color: light-dark($primary, $text-primary-dark) !important;
}
.text-secondary,
.text-secondary p {
  color: light-dark($secondary, $text-secondary-dark) !important;
}

.text-white,
.text-white p {
  color: light-dark($white, $black) !important;

  & > svg {
    fill: light-dark($white, $black) !important;
  }
}

.text-black,
.text-black p {
  color: light-dark($black, $white) !important;

  & > svg {
    fill: light-dark($black, $white) !important;
  }
}

.text-darkRed,
.text-darkRed p {
  color: $secondary;
}

.text-rose,
.text-rose p {
  color: $primary
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.link-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

// cursort pointer
.c-p {
  @include cursor-arrow;
}

// flex positioning

.flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-center-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bild-portrait img,
.bild-portrait .image {
  width: 100%;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  object-position: center;
  margin: 0;
}

.bild-landscape img,
.bild-landscape .image {
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  object-position: center;
  margin: 0;
}

.bild-square img,
.bild-square .image {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center;
  margin: 0;
}

// .bild-landscape .image,
// .bild-square .image,
// .bild-portrait .image {
//   overflow: hidden;
// }