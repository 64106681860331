.icon-list__item {
  width: 100%;

  @include lg-up {
    width: 33%;
    flex-grow: 3;
  }

  // loop through the list items and set the background color for each one of them based on the primary color and the index of the item
  @for $i from 1 through 6 {
    &:nth-child(#{$i}) {
      background-color: lighten($primary, ($i - 1) * 5%);
    }
  }

  & p {
    font-size: 2rem;
  }
}
