// Normal font-family
@font-face {
  font-family: 'SourceSansPro-Regular';
  src:
    url('./fonts/SourceSansPro-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Light font-family
@font-face {
  font-family: 'SourceSansPro-Light';
  src:
    url('./fonts/SourceSansPro-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Bold font-family
@font-face {
  font-family: 'SourceSansPro-Bold';
  src:
    url('./fonts/SourceSansPro-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
