.mediaBlock {
  background: $secondary-2;

  &__inner {
    padding: $spc-3;

    @include md-up {
      padding: $spc-8 $spc-6;
    }
  
    @include xl-up {
      padding: $spc-8;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;

    img {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      object-position: center;
    }
  }

  video {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: center;
  }

  .video-portrait {
    .image:has(video) {
      height: 80vh;
      max-height: 81rem;
      aspect-ratio: 9 / 16;
      overflow: hidden;
      margin: 0 auto;
    }

    video {
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin: 0;
      aspect-ratio: 9 / 16;
    }
  }

  .play__button {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: $primary;
    @include squaresize(4.8rem);
    @include cursor-arrow;

    &.show {
      display: block;
    }
  }
}