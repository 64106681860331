.contactForm {
  padding: $spc-3;

  @include md-up {
    padding: $spc-6;
  }

  @include xl-up {
    padding: $spc-8;
  }

  &__inner {
    background: light-dark(#F7F7F7, #444444);

    padding: $spc-3;

    @include md-up {
      padding: $spc-6;
    }

    @include xl-up {
      padding: $spc-6 $spc-14;
    }
  }

  &__info {

    a {
      color: $primary;
    }
  }

  input,
  label {
    font-size: 1.8rem;
  }

  .radio__wrapper.input__checkbox {
    input[type=checkbox] + label {
      font-size: 1.44rem;
      color: light-dark($black, $white);

      a {
        color: $primary;
      }
    }

    &:hover input[type=checkbox] + label  {
      color: light-dark($black, $white);
    }
  }

  .input-wrapper {
    margin-top: 1.6rem;
  }

  textarea {
    font-size: 1.8rem;
    font-family: $font-family-base;
  }
}

.contactForm__form {
  position: relative;
  overflow: hidden;
}

.contactForm__message {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: $spc-3;
  background-color: rgba(#d6d6d6, 0.9);
  transition: transform 0.3s ease-in-out;
  z-index: 10;

  &.hide {
    transform: translateY(-100%);
  }
}