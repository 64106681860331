.contact-block {
  &__meta {
    .icon {
      color: light-dark($black, $white);
      width: 1.6rem;
      height: 1.6rem;
      margin-right: .4rem;

      svg {
        fill: light-dark($black, $white);
      }
    }
  }
}