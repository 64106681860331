.teamList {
  background: rgba($secondary, 0.05);

  &__inner {
    padding: $spc-8 $spc-3;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: $spc-3;

    @include md-up {
      padding: $spc-8 $spc-5;
      grid-template-columns: repeat(2, 1fr);
    }

    @include xl-up {
      grid-template-columns: repeat(3, 1fr);
    }

    @include xl-up {
      padding: $spc-8 $spc-14;
      gap: $spc-8;
    }
  }

  @include md-up {
    &__item:nth-child(2n) {
      margin-top: $spc-5;
    }
  }

  @include lg-up {
    &__item:nth-child(2n) {
      margin-top: 0;
    }

    &__item:nth-child(3n+1) {
      margin-top: $spc-10;
    }
  
    &__item:nth-child(3n+2) {
      margin-top: $spc-20;
    }
  }

  &__item_data {
    padding-top: $spc-2;

    h3 {
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: $spc-2;
      }
    }
  }

}