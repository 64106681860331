.officeLocation {

  .twoColumn__right_image,
  .twoColumn__right_image img {
    aspect-ratio: 1 / 1;
  }

  .twoColumn__right_text {
    margin-bottom: $spc-2;

    p {
      margin-bottom: 0;
    }
  } 

  &__contacts * {
    font-size: 2.4rem;
  }
}