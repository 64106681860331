.contentBlock {

  &__inner {
    padding: $spc-10 $spc-3;

    @include md-up {
      padding: $spc-10 $spc-6;
    }

    @include xl-up {
      padding: $spc-20 $spc-8;
    }
  }

  .content-left,
  .content-right {

    @include lg-up {
      .contentBlock__text {
        width: 70%;
      }
    }

    @include xl-up {
      .contentBlock__text {
        width: 80%;
        padding: 0 $spc-14;
      }
    }
  }

  .content-right {
    @include lg-up {
      display: flex;
      justify-content: flex-end;
    }
  }

  .content-center {
    @include xl-up {
      .contentBlock__text {
        padding: 0 $spc-20;
      }
    }
  }
}