.readmore {
  .icon {
    width: 1.6rem;
    height: 1.6rem;
    padding: 0.4rem;
    transition: 0.16s margin ease;
  }

  &:hover {
    .icon {
      margin-left: 0.2rem;
      transition: 0.16s all ease;
    }
  }
}