.accordion {
  &__item {
    border-bottom: 0.1rem solid $gray-500;
    max-height: 6.6rem;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }

  &__title {
    padding: $spc-1 $spc-3 $spc-1;
    transition: all 0.5s ease;

    @include md-up {
      padding: $spc-1 $spc-6 $spc-1;
    }

    @include xl-up {
      padding: $spc-3 $spc-18 $spc-2;
    }

    & svg {
      width: 2.4rem;
      height: 2.4rem;
      color: light-dark($cta, $cta-dark);
    }

    .h3-light {
      font-family: $font-family-light;
    }

    .icon--arrow-down {
      display: block;
    }

    .icon--close {
      display: none;
    }
  }

  &__open {
    max-height: 1000vh;

    & .accordion__title {
      background: rgba(247, 47, 107, 0.20);

      & * {
        color: light-dark($primary, $white);
      }

      .icon--arrow-down {
        display: none;
      }
  
      .icon--close {
        display: block;
      }
    }
  }

  &__content {

    &_filter {
      padding: $spc-1 $spc-3 $spc-1;
      background-color: rgba(247, 47, 107, 0.20);

      @include md-up {
        padding: $spc-1 $spc-6 $spc-1;
      }

      @include xl-up {
        padding: $spc-3 $spc-18 $spc-2;
        gap: 3.2rem;
      }
    }

    &_header {

      h1 {
        margin-bottom: 0.8rem;
        color: $primary;
      }
  
      & * {
        color: $primary;
      }
    }

    &_block {
      width: 80%;
  
      &:nth-of-type(odd) {
        margin-left: 20%;
      }
    }

    &_contacts {
      .h1 {
        width: 60%;
        margin-left: 40%;
      }

      p {
        width: 70%;
      }
    }

    &_buttons {
      .btn {
        width: 100%;
        max-width: 100%;
        line-height: 180%;

        @include md-up {
          width: fit-content;
          max-width: fit-content;
        }
      }
    }
  }

  .block-wrapper {
    padding: $spc-3;

    @include lg-up {
      padding: $spc-8 $spc-22;
    }
  }
}