.filter {
  &__trigger {
    &.expanded {
      .filter__toggle {
        background-color: $primary;
        color: $white;

        .icon {
          transform: rotate(180deg);
        }
      }

      .filter__items {
        background-color: light-dark(rgba($primary, 0.06), rgba($primary, 0.36));
      }
    }
  }

  &__items {
    .radio__wrapper {
      margin-right: 2.4rem;
    }

    &__all {

      &:after {
        content: '';
        width: 0.1rem;
        height: 100%;
        background-color: rgba($black, 0.06);
        position: absolute;
        right: 0;
        top: 0;
        display: block;
      }
    }
  }

  &__toggle {
    .icon {
      width: 0.74rem;
    }
  }

  &__newsletter {
    .icon {
      width: 2rem;
      color: $primary;
    }
  }

  label {
    color: light-dark($primary, $white);
  }
}

.content__filter {

  @include lg-up {
    display: flex;
    gap: 1.6rem;
  }

  @include xl-up {
    gap: 3.2rem;
  }
}

.projectFilter {
  scroll-margin-top: 12rem;
}
 
.projectFilter__group {
  display: flex;
  flex-direction: column;
  gap: $spc-2;
  padding-top: $spc-1;
  

  @include md-up {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 0;
  }
}